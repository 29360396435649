import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Link from './Link';
import { ElementType, forwardRef, ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type ContextMenuLinkProps<C extends ElementType> = PolymorphicComponentPropWithRef<
  C,
  {
    className?: string;
    icon?: IconProp;
    isActive?: boolean;
    label?: ReactNode;
    theme?: 'bordered' | 'rounded';
  }
>;

type ContextMenuLinkComponent = (<C extends React.ElementType = typeof Link>(
  props: ContextMenuLinkProps<C>
) => React.ReactElement | null) & { displayName?: string };

// @ts-expect-error -- this gets handled by strictly typing the component above
const ContextMenuLink: ButtonComponent = forwardRef(
  <C extends React.ElementType = typeof Link>(
    {
      label = null,
      icon = null,
      theme = null,
      isActive = false,
      as,
      className,
      ...props
    }: ContextMenuLinkProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || Link;
    return (
      <Component
        ref={ref}
        className={cx(
          'text-left text-lg sm:text-sm flex items-center justify-between sm:flex-row-reverse sm:justify-start w-full py-3 sm:py-2 transition-colors duration-200 text-gray-800',
          {
            'px-6 border-t border-gray-300 sm:border-t-0': theme === 'bordered',
            'px-6 rounded-md': theme === 'rounded',
            'bg-gray-300': isActive,
            'hover:bg-gray-300': !isActive,
          },
          className
        )}
        {...props}
      >
        {label && <span className="grow">{label}</span>}
        {icon && (
          <span className={cx('shrink-0 text-xl', { 'pl-3 sm:pl-0 sm:pr-3': label })}>
            <FontAwesomeIcon icon={icon} size="1x" fixedWidth />
          </span>
        )}
      </Component>
    );
  }
);

ContextMenuLink.displayName = 'ContextMenuLink';

export default ContextMenuLink;
