import { useState, useEffect } from 'react';
import { NextSeo } from 'next-seo';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { Image } from 'cloudinary-react';
import { motion } from 'framer-motion';

import config from 'config';
import cx from 'lib/cx';
import { serializeToJSX } from 'lib/serializeTiptap';
import useCustomTheme from 'hooks/useCustomTheme';
import Button from 'components/common/Button';
import DonationForm from 'components/donation-form/DonationForm';

const QUERY = gql`
  query DonationFormPage($campaignId: String!) {
    findCampaigns(id: $campaignId) {
      id
      campaignDonateButtonLabel
      campaignHeaderBackgroundImage
      campaignStory
      formEnableRedirect
      formHeading
      formRedirectButtonLabel
      formRedirectUrl
      formSubHeading
      formThankYouMessage
      primaryColor

      ownerOrganization {
        id
        name
      }
    }
  }
`;

const DonationFormPage = ({ campaignId }) => {
  const [storyEl, setStoryEl] = useState(null);
  const [storyState, setStoryState] = useState('default'); // default, clamped, expanded

  const { data } = useQuery(QUERY, { variables: { campaignId } });
  const campaign = data?.findCampaigns[0];

  useCustomTheme({
    primary: campaign?.primaryColor,
    secondary: campaign?.primaryColor,
  });

  useEffect(() => {
    if (!storyEl) return;
    if (storyEl.scrollHeight > storyEl.clientHeight) setStoryState('clamped');
  }, [storyEl]);

  if (!campaign) return null;
  return (
    <>
      <NextSeo title={`Support ${campaign.ownerOrganization.name}`} />
      <div className="min-h-screen bg-theme-primary py-6 md:py-8 flex flex-col justify-center items-stretch [&>*]:shrink-0">
        <div className="container max-w-6xl lg:px-12">
          <div className="flex flex-col lg:flex-row-reverse gap-y-5 gap-x-12 lg:items-start">
            <motion.div className="bg-white rounded-xl overflow-hidden shadow-md lg:w-2/5" layout>
              <DonationForm
                campaignId={campaignId}
                config={{
                  formHeading: campaign.formHeading,
                  formSubHeading: campaign.formSubHeading,
                  formAccentColor: campaign.primaryColor,
                  formButtonLabel: campaign.campaignDonateButtonLabel,
                  formThankYouMessage: campaign.formThankYouMessage,
                  ...(campaign.formEnableRedirect && {
                    formRedirectLabel: campaign.formRedirectButtonLabel,
                    formRedirectUrl: campaign.formRedirectUrl,
                  }),
                  isDonationPage: true,
                }}
              />
            </motion.div>
            <motion.div
              className="bg-white p-5 md:p-10 rounded-xl lg:flex-1 space-y-5 lg:space-y-10 transition-all duration-1000"
              layout
            >
              {campaign.campaignHeaderBackgroundImage ? (
                <Image
                  publicId={campaign.campaignHeaderBackgroundImage}
                  alt=""
                  className="block w-full rounded-2xl shadow-md"
                  fetchFormat="auto"
                  gravity="faces:auto"
                  aspectRatio="4:3"
                  crop="fill"
                />
              ) : (
                <Image
                  publicId={config('/defaultCampaignHero')}
                  alt=""
                  className="block w-full rounded-2xl shadow-md"
                  aspectRatio="4:3"
                  crop="fill"
                  width="1400"
                />
              )}
              <div>
                <div
                  ref={setStoryEl}
                  className={cx(
                    'leading-relaxed text-base lg:text-lg transition-all duration-1000 rich-text',
                    {
                      'line-clamp-5 lg:line-clamp-[7]': storyState !== 'expanded',
                    }
                  )}
                >
                  {serializeToJSX(campaign.campaignStory)}
                </div>
                {storyState === 'clamped' && (
                  <Button
                    as="button"
                    type="button"
                    color="gray-300"
                    padding="sm"
                    radius="full"
                    className="font-medium text-sm mt-5"
                    onClick={() => setStoryState('expanded')}
                    outline
                  >
                    Read More <FontAwesomeIcon icon={faChevronDown} size="1x" className="ml-2.5" />
                  </Button>
                )}
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

DonationFormPage.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

DonationFormPage.defaultProps = {};

export default DonationFormPage;
